import { SVGProps, memo } from "react";

function Copy(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
            <path
                d="M10 4.5V3a1.5 1.5 0 00-1.5-1.5H3A1.5 1.5 0 001.5 3v5.5A1.5 1.5 0 003 10h1.5M10 4.5h1A1.5 1.5 0 0112.5 6v5a1.5 1.5 0 01-1.5 1.5H6A1.5 1.5 0 014.5 11v-1M10 4.5H6A1.5 1.5 0 004.5 6v4"
                stroke="#7A869B"
                strokeWidth={props.strokeWidth || 1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoCopy = memo(Copy);
export default MemoCopy;
