"use client";

import { StytchProvider } from "@stytch/nextjs";
import { createStytchUIClient } from "@stytch/nextjs/ui";
// import { StatsigProvider } from "statsig-react";
import { EventsContextProvider } from "@app/dashboard/EventsContext";

const env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;
// const tier = env === "production" ? "production" : "development";

export function Providers({ children }) {
    const public_token =
        env === "production"
            ? process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN_PROD
            : process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN_DEV;
    const stytch = createStytchUIClient(public_token as string);
    return (
        // @ts-ignore
        // <StatsigProvider
        //     sdkKey="client-Gok6GKwsPAmn1xdhi2oYB0Kja78fh5cEB4k8VxmWQQH"
        //     waitForInitialization={false}
        //     options={{ environment: { tier } }}
        //     user={{}}
        // >
        <StytchProvider stytch={stytch}>
            <EventsContextProvider>{children}</EventsContextProvider>
        </StytchProvider>
        // </StatsigProvider>
    );
}
