import(/* webpackMode: "eager" */ "/vercel/path1/app/analytics/FacebookAnalytics.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/app/DiscordFeedback.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/app/MobileView.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/app/Providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"openSans\"}");
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Red_Hat_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"redHatDisplay\"}");
import(/* webpackMode: "eager" */ "/vercel/path1/src/styles/output.css")