"use client";

import { createContext, useContext } from "react";
import { Socket } from "socket.io-client";
import useSocketEvents from "@app/dashboard/useSocketEvents";

const EventsContext = createContext({} as Socket);

const EventsContextProvider = ({ children }) => {
    const socket = useSocketEvents();

    return <EventsContext.Provider value={socket}>{children}</EventsContext.Provider>;
};

const useEventsContext = () => useContext(EventsContext);

export { useEventsContext, EventsContextProvider };
