import { SVGProps, memo } from "react";

function DiscordMessaging(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 149 141" fill="none" {...props}>
            <path
                d="M119.311 81.194c-3.209-2.548-6.84-4.135-10.885-4.7-4.045-.564-8.005-.16-11.839 1.301-3.833 1.462-7.029 3.768-9.58 6.98-2.55 3.212-4.098 6.882-4.68 10.982a23.488 23.488 0 001.364 11.894c1.502 3.839 3.81 7.022 7.019 9.57 2.093 1.662 5.036 3.041 8.891 4.131 3.856 1.09 7.694 1.772 11.479 2.018 3.195.059 6.819.008 10.837-.182 4.017-.189 10.56-.908 12.055-1.072l-.99-7.994c-.393-3.803-1.155-7.787-2.348-11.944a68.464 68.464 0 00-4.81-12.209c-1.968-4.04-4.175-6.919-6.513-8.775z"
                fill="#5662F6"
            />
            <rect width={128} height={128} rx={64} fill="#5662F6" />
            <path
                d="M91.726 37.959c-5.082-2.32-10.565-4-16.288-4.959-.12 0-.2.04-.28.12-.72 1.24-1.48 2.84-2.04 4.079-6.164-.92-12.287-.92-18.29 0-.52-1.28-1.32-2.84-2.04-4.079-.04-.08-.16-.12-.24-.12-5.724.96-11.206 2.68-16.289 4.959-.04 0-.08.04-.12.08C25.774 53.275 22.933 68.15 24.333 82.827c0 .08.04.16.12.2 6.804 4.959 13.447 7.958 19.93 9.957.12.04.2 0 .28-.08 1.521-2.08 2.922-4.239 4.082-6.558.08-.12 0-.28-.12-.36-2.16-.8-4.242-1.8-6.243-2.92-.16-.08-.16-.319-.04-.399.4-.32.84-.64 1.24-.96.08-.04.16-.08.24-.04 13.087 5.879 27.294 5.879 40.22 0 .08-.04.2-.04.28.04.4.32.801.64 1.241.96.16.12.12.32-.04.4a40.266 40.266 0 01-6.243 2.92c-.16.04-.2.2-.12.359a49.514 49.514 0 004.082 6.518c.08.08.16.12.28.08 6.523-2 13.167-4.998 20.01-9.917.04-.04.08-.12.12-.2 1.681-16.996-2.801-31.712-11.846-44.788l-.08-.08zm-41.02 35.95c-3.962 0-7.203-3.559-7.203-7.918 0-4.358 3.201-7.918 7.203-7.918 4.042 0 7.244 3.6 7.204 7.918 0 4.36-3.202 7.918-7.204 7.918zm26.613 0c-3.962 0-7.203-3.559-7.203-7.918 0-4.358 3.201-7.918 7.203-7.918 4.042 0 7.244 3.6 7.204 7.918 0 4.36-3.162 7.918-7.204 7.918z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoDiscordMessaging = memo(DiscordMessaging);
export default MemoDiscordMessaging;
