import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;
const host = env === "production" ? "prod" : "dev";

export default function useSocketEvents() {
    const [socket, setSocker] = useState({} as Socket);

    useEffect(() => {
        const s = io(`https://api-${host}.blockbeat.io`);

        s.on("connect", () => {
            // console.log(`Connected to ${host} Events websocket`);
            setSocker(s);
        });

        return () => {
            s.disconnect();
        };
    }, []);

    return socket;
}
