import { Mode } from "@/types/util";

const env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;
const EMAIL_API_URL =
    (process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
        (env === "production" ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`)) + "/email";

export async function getAccessListContacts(jwt: string): Promise<Array<any>> {
    const res = await fetch(`${EMAIL_API_URL}/access-list-contacts`, {
        headers: { jwt },
    });

    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }

    return res.json();
}

export async function getVerifiedAccessListContacts(jwt: string): Promise<Array<any>> {
    const res = await fetch(`${EMAIL_API_URL}/verified-access-list-contacts`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export async function getReferredUsersList(jwt: string): Promise<Array<any>> {
    const res = await fetch(`${EMAIL_API_URL}/referred-users-list`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const viewOnDesktopEmail = async ({
    to,
    href,
    origin,
}: {
    to: string;
    href: string;
    origin: string;
}) => {
    const res = await fetch(`${EMAIL_API_URL}/send-view-on-desktop`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ to, href, origin }),
    });

    if (!res.ok) {
        throw new Error("Failed to post data");
    }

    return await res.json();
};

export const sendSuccessfulProSubscriptionEmail = async ({
    mode,
    jwt,
    origin,
}: {
    mode: Mode;
    jwt: string;
    origin: string;
}) => {
    const res = await fetch(`${EMAIL_API_URL}/send-pro-subscription-email`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ mode, origin }),
    });

    if (!res.ok) {
        throw new Error("Failed to send email");
    }

    return await res.json();
};

export const requestBetaAccessCode = async (jwt: string) => {
    const res = await fetch(`${EMAIL_API_URL}/request-beta-access-code`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
    });

    if (!res.ok) {
        throw new Error("Failed to post data");
    }

    return await res.json();
};

export const verifyBetaAccessCode = async ({ jwt, code }: { jwt: string; code: string }) => {
    const res = await fetch(`${EMAIL_API_URL}/verify-beta-access-code`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ code }),
    });

    if (!res.ok) {
        throw new Error("Failed to post data");
    }

    return await res.json();
};

export const sendWelcomeEmail = async (jwt: string) => {
    const res = await fetch(`${EMAIL_API_URL}/send-welcome-email`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
    });

    if (!res.ok) {
        throw new Error("Failed to send email");
    }

    return await res.json();
};

export const sendSuccessfulPasswordResetEmail = async ({ jwt, origin }: { jwt: string; origin: string }) => {
    const res = await fetch(`${EMAIL_API_URL}/send-successful-password-reset-email`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ origin }),
    });

    if (!res.ok) {
        throw new Error("Failed to send email");
    }

    return await res.json();
};

export const verifyAffiliateCode = async ({ jwt, referralCode }: { jwt: string; referralCode: string }) => {
    const res = await fetch(`${EMAIL_API_URL}/verify-affiliate-code`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ referralCode }),
    });

    if (!res.ok) {
        throw new Error("Failed to verify affiliate code");
    }

    return await res.json();
};
